import React, {useState, useContext, useEffect} from 'react';
import {useNavigate} from "react-router-dom";
import { ThemeContext, UserLinkContext, FormContext } from './Contexts.js';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { submitForm } from './services/ApiService.js';
import TempLeftJoinedInputGroup from './TempLeftJoinedInputGroup.js';


export function GroupStatsForm() {
    const theme = useContext(ThemeContext);
    const userLink = useContext(UserLinkContext);
    const formContext = useContext(FormContext);
    
    const navigate = useNavigate();
    const navigateToSuccessPage = () => navigate('/forms/success/' + userLink);
    const navigateToErrorPage = () => navigate('/forms/error/' + userLink);
    const navigateToForbiddenPage = () => navigate('/forms/forbidden/' + userLink);

    const [validated,
        setValidated] = useState(false);
    const formData = formContext.formData
    const setFormData = formContext?.updateFormData;

    const chngFn = (event) => {
        const {name, value} = event.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    async function submit(target) {
        target.preventDefault();
        
        if (target.currentTarget.checkValidity() === false) {
            target.stopPropagation();
            setValidated(true);
            return;
        }
        setValidated(true);

        await submitForm(userLink, formData)
            .then(res => navigateToSuccessPage())
            .catch(e => {
                if (e
                    ?.response
                        ?.status === 403) {
                    navigateToForbiddenPage();
                } else {
                    navigateToErrorPage();
                }
            });
    }
    return (
        <Container className="p-3" style={theme}>

            <Container
                className="p-5 mb-4 bg-light rounded-3"
                style={{
                "opacity": "0.96"
            }}>
                <h1 className="header">Форма для заповнення</h1>
                <Form
                    style={{
                    "--mdb-bg-opacity": "0.5"
                }}
                    noValidate
                    validated={validated}
                    onSubmit={(e) => submit(e)}>                        
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label>За штатом</Form.Label>
                        <Form.Control
                            type="number"
                            placeholder="Вкажіть число"
                            onChange={chngFn}
                            name="byState" value={formData.byState}/>
                        <Form.Control.Feedback type="invalid">
                            Лише цілі числа
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label>За списком юридично</Form.Label>
                        <Form.Control type="number"
                            placeholder="Вкажіть число"
                            onChange={chngFn}
                            name="byListOnPaper" value={formData.byListOnPaper}/>
                        <Form.Control.Feedback type="invalid">
                            Лише цілі числа
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label>За списком фактично</Form.Label>
                        <Form.Control type="number"
                            placeholder="Вкажіть число"
                            onChange={chngFn}
                            name="byListAllActual" value={formData.byListAllActual}/>
                        <Form.Control.Feedback type="invalid">
                            Лише цілі числа
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label>СЗ (Спец Захід)</Form.Label>
                        <Form.Control type="number"
                            placeholder="Вкажіть число"
                            onChange={chngFn}
                            name="specialEvent" value={formData.specialEvent}/>
                        <Form.Control.Feedback type="invalid">
                            Лише цілі числа
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label>ЗЗБ (Захід Забезпечення Безпеки)</Form.Label>
                        <Form.Control
                            type="number"
                            placeholder="Вкажіть число"
                            onChange={chngFn}
                            name="securityMeasureEvent" value={formData.securityMeasureEvent}/>
                        <Form.Control.Feedback type="invalid">
                            Лише цілі числа
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label>БЧ (Бойове Чергування)</Form.Label>
                        <Form.Control type="number"
                            placeholder="Вкажіть число"
                            onChange={chngFn}
                            name="combatDuty" value={formData.combatDuty}/>
                        <Form.Control.Feedback type="invalid">
                            Лише цілі числа
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label>Відпустка (короткотривала)</Form.Label>
                        <Form.Control type="number"
                            placeholder="Вкажіть число"
                            onChange={chngFn}
                            name="vacation" value={formData.vacation}/>
                        <Form.Control.Feedback type="invalid">
                            Лише цілі числа
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label>Лікарняний</Form.Label>
                        <Form.Control type="number"
                            placeholder="Вкажіть число"
                            onChange={chngFn}
                            name="sickLeave" value={formData.sickLeave}/>
                        <Form.Control.Feedback type="invalid">
                            Лише цілі числа
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label>9 год робочий день</Form.Label>
                        <Form.Control type="number"
                            placeholder="Вкажіть число"
                            onChange={chngFn}
                            name="workingDay9h" value={formData.workingDay9h}/>
                        <Form.Control.Feedback type="invalid">
                            Лише цілі числа
                        </Form.Control.Feedback>
                    </Form.Group>
                    
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label>Відрядження (навчання, збори, тощо)</Form.Label>
                        <Form.Control
                            type="number"
                            placeholder="Вкажіть число"
                            onChange={chngFn}
                            name="workingAssignment" value={formData.workingAssignment}/>
                        <Form.Control.Feedback type="invalid">
                            Лише цілі числа
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label>ОБЗ (Оперативно Бойові Заходи)</Form.Label>
                        <Form.Control
                            type="number"
                            placeholder="Вкажіть число"
                            onChange={chngFn}
                            name="obz" value={formData.obz}/>
                        <Form.Control.Feedback type="invalid">
                            Лише цілі числа
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label>Вихідний (1.5 год на збір)</Form.Label>
                        <Form.Control
                            type="number"
                            placeholder="Вкажіть число"
                            onChange={chngFn}
                            name="weekendWithGatherTime" value={formData.weekendWithGatherTime}/>
                        <Form.Control.Feedback type="invalid">
                            Лише цілі числа
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label>Збір</Form.Label>
                        <Form.Control type="number"
                            placeholder="Вкажіть число"
                            onChange={chngFn}
                            name="gathering" value={formData.gathering}/>
                        <Form.Control.Feedback type="invalid">
                            Лише цілі числа
                        </Form.Control.Feedback>
                    </Form.Group>

                    <TempLeftJoinedInputGroup
                    stateLeftInit={formData.stateLeft} 
                    stateJoinedInit={formData.stateJoined}
                    onChange={(e) => {
                        setFormData({
                            ...formData,
                            ["tempLeftList"]: e.tempLeftList,
                            ["stateLeft"]: e.stateLeft,
                            ["stateJoined"]: e.stateJoined
                        });
                    }}/>

                    <div className="d-grid gap-2 main-button">
                        <Button variant="success" size="lg" type="submit">
                            Завершити звіт
                        </Button>
                    </div>
                </Form>
            </Container>
        </Container>
    );
}